import React, { useState } from "react";
import Logo from "../../assets/images_td/appImages/logo.svg";
import { UseForgetPasswordForm } from "./requestForm/UseForgetPasswordForm";
import ErrorMessage from "../common/ErrorMessage";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { onCloseModal } from "../../utils/closeModal";
import { UseResetPasswordForm } from "./requestForm/UseResetPasswordForm";
import ConnexionModal from "../shared/FrontHeader/modal/ConnexionModal";
import { MdMail } from "react-icons/md";
import PhoneInput from "react-phone-input-2";
import { RiLockFill } from "react-icons/ri";
import { GoNumber } from "react-icons/go";



function ResetPassword() {
  const [openModal, setOpenModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const onOpenModal = () => {
    setOpenModal(true);
  };

  const {
    register,
    errors,
    onSubmit,
    isLoading,
    passwordHandleChange,
    haveMinCharacter,
    haveMinLowercase,
    haveMinNumber,
    haveMinSpecialCharacter,
    haveMinUppercase,
    handleChangePhone,
    phone,
  } = UseResetPasswordForm(onOpenModal);
  return (
    <div className="container-page-login-app">
      <div className="auth-row">
        <div className="auth-col auth-right-side-col">
          <div className="auth-right-side-container">
            <div className="auth-form-container text-center pb-4 w-100">
              <div className="content-logo-auth pb-2 pt-5">
                <img
                  loading="lazy"
                  src={Logo}
                  alt="logo"
                  className="logo-auth-page"
                />
              </div>
              <div className="text-center pb-4">
                <h2 className="title-login-modal">
                  Réinitialiser votre mot de passe
                </h2>
              </div>
              <form className="mt-4" onSubmit={onSubmit}>
                <div className="row row-add-student">
                  <div className="col-md-12 auth-input-col mb-3">
                    <label htmlFor="email" className="auth-form-label pb-2">
                      Code
                    </label>
                    <div className="auth-form-group-rounded">
                      <span className="icon-input">
                        <GoNumber />
                      </span>
                      <input
                        type={`text`}
                        className="form-control auth-form-control"
                        id="code"
                        placeholder="Code envoyer par email"
                        aria-label="code"
                        {...register("code")}
                      />
                      <ErrorMessage
                        message={errors?.code && errors?.code?.message}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 auth-input-col mb-3">                  
                    <PhoneInput
                      inputProps={{
                        name: "phone",
                      }}
                      defaultMask={".. ... .. .."}
                      country="sn"
                      value={phone}
                      enableSearch={true}
                      onChange={(phone, country) => handleChangePhone(phone, country)}
                      inputClass="form-control auth-form-control-telephone10"
                      enableAreaCodes={true}
                      prefix="+"
                      countryCodeEditable={false}
                    />
                    <ErrorMessage
                      message={
                        errors?.telephone && errors?.telephone?.message
                      }
                    />
                  </div>
                  <div className="col-md-12 auth-input-col mb-3">
                    <label
                      htmlFor="new_password"
                      className="auth-form-label pb-2"
                    >
                      Nouveau mot de passe
                    </label>
                    <div className="auth-form-group-rounded">
                      <span className="icon-input">
                        <RiLockFill />
                      </span>
                      <input
                        type={`password`}
                        className="form-control auth-form-control"
                        id="new_password"
                        placeholder="Mot de passe"
                        aria-label="new_password"
                        onChange={passwordHandleChange}
                      />
                      <ErrorMessage
                        message={
                          errors?.new_password && errors?.new_password?.message
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-12 auth-input-col mb-3">
                    <label
                      htmlFor="new_password_confirm"
                      className="auth-form-label pb-2"
                    >
                      Confirmer mot de passe
                    </label>
                    <div className="auth-form-group-rounded">
                      <span className="icon-input">
                        <RiLockFill />
                      </span>
                      <input
                        type={`password`}
                        className="form-control auth-form-control"
                        id="new_password_confirm"
                        placeholder="Confirmer mot de passe"
                        aria-label="Confirmer"
                        {...register("new_password_confirm")}
                      />
                      <ErrorMessage
                        message={
                          errors?.new_password_confirm &&
                          errors?.new_password_confirm?.message
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input-group my-2 password-validation-terms-row">
                      <div className="password-validation-terms-container">
                        <h4 className="password-validation-terms-title">
                          Votre mot de passe doit contenir :{" "}
                        </h4>
                        <ul className="password-validation-terms-ul p-l-14 text-start">
                          <li
                            className={
                              "password-rule-item " +
                              (!!haveMinCharacter && "text-success")
                            }
                          >
                            Au minimum 8 caractères
                          </li>
                          <li
                            className={
                              "password-rule-item " +
                              (!!haveMinUppercase && "text-success")
                            }
                          >
                            Au minimum 1 majuscule
                          </li>
                          <li
                            className={
                              "password-rule-item " +
                              (!!haveMinLowercase && "text-success")
                            }
                          >
                            Au minimum 1 minuscule
                          </li>
                          <li
                            className={
                              "password-rule-item " +
                              (!!haveMinNumber && "text-success")
                            }
                          >
                            Au minimum 1 chiffre
                          </li>
                          <li
                            className={
                              "password-rule-item " +
                              (!!haveMinSpecialCharacter && "text-success")
                            }
                          >
                            Au minimum 1 caractère spécial
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 d-flex justify-content-center pt-5">
                    <button
                      // to="/admin/dashboard"
                      className="btn auth-submit-btn trans-0-2"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                            role="status"
                          ></span>
                          <span>Enregistrement...</span>
                        </>
                      ) : (
                        "Enregistrer"
                      )}
                    </button>
                  </div>
                </div>
              </form>
              <ConnexionModal
                modalConnexion={openModal}
                setModalConnexion={setOpenModal}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
