import React from "react";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import FrontFooter from "../../shared/FrontFooter/FrontFooter";
import illustration from "../../../assets/images_td/appImages/illustration.png";
import founder1 from "../../../assets/images_td/appImages/founder_1.png";
import founder2 from "../../../assets/images_td/appImages/founder_2.png";
import {
  AiOutlineGoogle,
  AiOutlineTwitter,
  AiFillFacebook,
  AiFillLinkedin,
} from "react-icons/ai";
import { TeamData } from "./data";
import { Link } from "react-router-dom";
import FooterPage from "../../auth/FooterPage";
import { OpinionData } from "./data";

const CustomBeautyTitle = ({ title }: { title: string }) => {
  return <h2 className="custom-esb-title">{title}</h2>;
};

const TeamCard = ({
  photo,
  name,
  role,
  twitter,
  facebook,
  linkedin,
}: {
  photo?: string;
  name: string;
  role: string;
  twitter?: string;
  facebook?: string;
  linkedin?: string;
}) => {
  return (
    <div className="team-card">
      <span className="photo-wrapper">
        <img src={photo} alt="photo" />
      </span>
      <span className="name">{name}</span>
      <span className="role">{role}</span>
      <div className="social-networks d-flex align-items-center justify-content-center gap-2">
        <Link target="_blank" to={`#`}>
          <AiOutlineGoogle />
        </Link>
        <Link target="_blank" to={`${twitter}`}>
          <AiOutlineTwitter />
        </Link>
        <Link target="_blank" to={`${facebook}`}>
          <AiFillFacebook />
        </Link>
        <Link target="_blank" to={`${linkedin}`}>
          <AiFillLinkedin />
        </Link>
      </div>
    </div>
  );
};

function WhoWeAre() {
  return (
    <div className="landing-page-component">
      <FrontHeader />
      <div className="page-content who-we-are-page pb-5 pb-5">
        <h1 className="custom-page-title">Qui sommes-nous ?</h1>
        <div className="who-we-are">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="illustration-container">
                  <img src={illustration} alt="image" />
                </div>
              </div>
              <div className="col-md-6">
                <CustomBeautyTitle title="Qui sommes nous?" />
                <h3 className="custom-title">ToutDedans</h3>
                <div className="custom-content">
                  ToutDedans est une boutique en ligne qui propose une large
                  gamme de produits pour tous les besoins. Que vous soyez à la
                  recherche d’articles de bébé, d’enfants, des produits de
                  beauté ou encore des chaussures Femme et Homme, vous trouverez
                  tout ce dont vous avez besoin sur ToutDedans.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="plateform-founders">
          <div className="container">
            <CustomBeautyTitle title="Que disent nos clients?" />
            <h3 className="custom-title">Quelques avis</h3>
            <div className="row pt-4">
              {OpinionData.map((opn, key) => {
                return (
                  <div
                    className="col-md-6 mb-4 custom_card__wrapper"
                    key={`${key}_${opn.id}`}
                  >
                    <div className="founder-card" key={key}>
                      <span className="photo-wrapper">
                        <img src={opn.photo} alt="photo" />
                      </span>
                      <span className="name">{opn.name}</span>
                      <span className="description">{opn.message}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {/* <div className="our-team">
          <div className="container">
            <CustomBeautyTitle title="Team" />
            <h3 className="custom-title">Notre équipe</h3>
            <div className="row pt-4">
              {!!TeamData &&
                TeamData.map((member, key) => {
                  return (
                    <div className="col-md-3 card-wrapper">
                      <TeamCard
                        photo={member.photo}
                        name={member.name}
                        role={member.role}
                        twitter={member.twitter}
                        facebook={member.facebook}
                        linkedin={member.linkedin}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div> */}
      </div>
      {/* <FooterPage /> */}
      <FrontFooter />
    </div>
  );
}

export default WhoWeAre;
