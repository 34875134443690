type ENV = "dev" | "prod" | "demo";

export const currentEnv: string = "prod";

const env: string = currentEnv;

export const Env = env;

const API_DEV_URL = "https://easy-market-api.withvolkeno.com";
const API_DEMO_URL = "https://easy-market-api.withvolkeno.com";
const API_PROD_URL = "https://api.easymarket.sn";

const VENDEUR_APIKEY_DEMO =
  "6eae18ea6df5a30e52b9a75e42619d109589de28c01c76c0610a3893b226a5df";
const VENDEUR_APIKEY_PROD =
  "d94e2aab542eb7f3c57c2ecf5301580d575aa6e4ca1bb38a1ca1f0af0e3a84c6";

export const APP_URL_DEV = "https://easy-market.volkeno-engineering.click/";
export const APP_URL_DEMO = "https://easy-market.volkeno-engineering.click/";
export const APP_URL_PROD = "https://toutdedans.sn/";

function processApiUrl() {
  if (env === "prod") return API_PROD_URL;
  if (env === "demo") return API_DEMO_URL;
  return API_DEV_URL;
}

function processAppUrl() {
  if (env === "prod") return APP_URL_PROD;
  if (env === "demo") return APP_URL_DEMO;
  return APP_URL_DEV;
}

function processApikey() {
  if (env === "prod") return VENDEUR_APIKEY_PROD;
  if (env === "demo") return VENDEUR_APIKEY_DEMO;
  return VENDEUR_APIKEY_DEMO;
}

export const ApiBaseUrl = processApiUrl();
export const AppBaseUrl = processAppUrl();

export const VendeurApikey = processApikey();

export const GoogleApikey = "AIzaSyD0klc1PQS8QK--Be3rF3i8kW_idy04AVQ";

//export const IntechApikey = "554ACA96-F050-442D-8E29-BEE77FAE821A";

export const IntechApikey =
  "d46c0fdb5c1135b60cb9ba8407888ed533e1cd4dd4bf442ab6d6139ae485688c";

export const GoogleClientId =
  "252665710140-5euq0h9htj81foss4ur1gllbbl8qtig9.apps.googleusercontent.com";
