import photo1 from "../../../assets/images_td/appImages/founder_1.png";
import photo2 from "../../../assets/images_td/appImages/founder_2.png";
import photo3 from "../../../assets/images_td/appImages/team_3.png";
import photo4 from "../../../assets/images_td/appImages/team_4.png";
import avatar from "../../../assets/images_td/appImages/Avatar.png";

export const TeamData = [
    {
        id: 1,
        photo: photo1,
        name: "Adjaratou K. NDIAYE",
        role: "Chef de projet",
        twitter: "https://twitter.com",
        facebook: "https://facebook.com",
        linkedin: "https://linkedin.com"   
    },
    {
        id: 2,
        photo: photo3,
        name: "Red Team de Bakeli",
        role: "Developpement mobile",
        twitter: "https://twitter.com",
        facebook: "https://facebook.com",
        linkedin: "https://linkedin.com"   
    },
    {
        id: 3,
        photo: photo2,
        name: "Volkeno Team",
        role: "Developpement WEB",
        twitter: "https://twitter.com",
        facebook: "https://facebook.com",
        linkedin: "https://linkedin.com"   
    },
    {
        id: 4,
        photo: photo4,
        name: "Fatim SEYE",
        role: "Digital marketer",
        twitter: "https://twitter.com",
        facebook: "https://facebook.com",
        linkedin: "https://linkedin.com"   
    },
];


export const OpinionData = [
    {
        id: 1,
        photo: avatar,
        name: "Awa",
        message: "Bonjour Madame, J’accuse réception de tous les articles et vous assure ma satisfaction. Le petit est hyper heureux"
    },
    {
        id: 2,
        photo: avatar,
        name: "Babacar",
        message: "Satisfaction totale. Comme sur mesure 🤣🤣"
    },
    {
        id: 3,
        photo: avatar,
        name: "Fatou",
        message: "Commande bien reçue. Je suis plus que satisfaite et j’ai été agréablement surprise par votre professionnalisme. Merci pour le cadeau aussi ❤️"
    },
    {
        id: 4,
        photo: avatar,
        name: "Anta",
        message: "Je viens de recevoir le colis, rien à dire. Je suis très satisfaite."
    },

]