import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import UseLoginForm from "../../../auth/requestForm/UseLoginForm";
import CloseModalBtn from "../../CloseModalBtn";
import Logo from "../../../../assets/images_td/appImages/logo.svg";
import { useStepType } from "../../../../utils/type";
import { useStep } from "react-hooks-helper";
import LoginTab from "../../../auth/Tabs/LoginTab";
import RegisterTab from "../../../auth/Tabs/RegisterTab";

const steps = [
  { id: "login-tab", Component: LoginTab },
  { id: "register-tab", Component: RegisterTab },
];
const tabs = ["Connexion", "Inscription"];

const ConnexionModal = ({ modalConnexion, setModalConnexion }) => {
  function closeModalConnexion() {
    setModalConnexion(false);
  }

  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;

  UseLoginForm(closeModalConnexion);
  return (
    <Modal show={modalConnexion} onHide={closeModalConnexion}>
      <div className="auth-row">
        <div className="auth-form-container pb-4">
          <Modal.Header className="modal-header pe-2 py-1 flex-r-header-modal">
            <CloseModalBtn onClick={closeModalConnexion} />
          </Modal.Header>
          <Modal.Body>
            <div className="content-logo-auth pb-4 pt-1 text-center">
              <img
                loading="lazy"
                src={Logo}
                alt="logo"
                className="logo-auth-page"
              />
            </div>
            <div className="tabs-main-content">
              <ul className="tabs-container">
                {tabs.map((tab, key) => (
                  <li
                    className={`
                  tab-item 
                  ${index === key && "tab-item-active"}
                `}
                    onClick={() => go(key)}
                    key={key}
                  >
                    {tab}
                  </li>
                ))}
              </ul>
            </div>
            <div className="pt-3">
              <div className="tabs-content-value">
                <Component closeModalConnexion={closeModalConnexion} go={go} />
              </div>
            </div>
          </Modal.Body>
        </div>
      </div>
    </Modal>
  );
};

export default ConnexionModal;
